.closed {
  background-color: #DC1500;
  padding: 5px 20px;
  border-radius: 5px;
  color: #fff;
}
.inProgress, .Open {
  background-color: #1477F8;
  padding: 5px 20px;
  border-radius: 5px;
  color: #fff;
}

.onHold {
  background-color: #F2994A;
  padding: 5px 20px;
  border-radius: 5px;
  color: #fff;
}
.solved {
  background-color: #6FCF97;
  padding: 5px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
}