* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.small-logo {
  width: 21%;
}
.nav_list {
  padding: 0;
  margin: 0;
}

.nav_list li a:hover > svg path {
  fill: #ff4e00;
}
.nav_list li a.active > svg path {
  fill: #ff4e00;
}
.css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  .MuiAvatar-root {
  width: 32px;
  height: 32px;
  margin-left: -4px;
  margin-right: 8px;
  color: gray;
  background: none;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 12px;
  gap: 15px;
  transition: all 0.5s;
  width: 200px;
  align-items: center;
  justify-content: center;
}

.sidebarandnavbar {
  flex-direction: column;
  display: flex;
}

/* ------css----- */

.main-content {
  display: flex;
}

main {
  width: 100%;
  background-color: #27559826;

  padding: 20px;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  padding-bottom: 50px;
}

.sidebar {
  background: #275598;
  color: #fff;
  transition: all 0.5s;
  min-height: calc(100vh - 50px);
}

.top-section {
  background: #275598;
  flex-direction: row;
  align-items: center;
  padding: 15px 15px;
  height: 60px;
}

.logo {
  height: auto;
  margin-left: 20px;
}

.bars {
  font-size: 20px;

  display: flex;
  cursor: pointer;
  color: #fff;
}

.link:hover {
  background: #fff;
  color: #ff4e00;
  transition: all 0.5s;
}

.active {
  background: #fff;
  color: #ff4e00;
}
a.active {
  background-color: #fff;
  border-radius: 0;
  border-left: 3px solid #ff4e00;
}
.icon {
  padding: 5px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
}

.link_text {
  font-size: 15px;
}
.active .icon,
.icon :hover {
  font-size: 20px;
  background: #fff;
  color: #ff4e00;
}

.css-5nwj3y {
  justify-content: space-between;
  padding-right: 15px;
  box-shadow: 0 0 10px 0 #dedede;
}

.number-cards {
  display: flex;
  justify-content: center;
  margin: auto auto;
}

.dashbord {
  padding: 16px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #1976d2;
}

.head-dashbord {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* ---- media query ---- */
@media screen and (max-width: 767px) {
  .link_text {
    display: none;
  }
}
