/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

p.closed {
  background-color: #dc1500;
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
  color: #fff;
}
p.inProgress,
p.Open {
  background-color: #1477f8;
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
  color: #fff;
}
p.onHold {
  background-color: #f2994a;
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
  color: #fff;
}
p.solved {
  background-color: #6fcf97;
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
}
.status-line {
  width: 3px;
  height: 300px;
  background-color: #333;
}
.status-one {
  width: 30px;
  height: 30px;
  background-color: #333;
  border-radius: 50px;
  text-align: center;
  padding: 5px 0 0 0;
}
.tracking-item {
  position: relative;
  padding: 0 0 0 25px;
  font-size: 0.9rem;
  margin-left: 1rem;
  min-height: 1rem;
}
.tracking-item .tracking-icon.status-intransit {
  color: #f2994a;
  font-size: 0.6rem;
}
.tracking-item .tracking-icon {
  position: absolute;
  left: -15px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  background-color: #fff;
  color: #fff;
}
.tracking-icon.status-intransit p {
  position: absolute;
  color: #fff;
  font-size: 16px;
}
.tracking-icon.status-intransit.hold {
  color: #f2994a;
}
.tracking-icon.status-intransit.Progress {
  color: #1477f8;
}
.tracking-icon.status-intransit.Solved {
  color: #6fcf97;
}
.tracking-icon.status-intransit.Closed {
  color: #dc1500;
}
