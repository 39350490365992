
.form-main {
    border-radius: 4px;
    background-color: transparent !important;
  }
  .bg-white {
    --bs-bg-opacity: 0 !important;
  }
  .form-content {
    border-radius: 4px;
    background-color: #fff !important;
    padding: 20px;
  }
  .mng-scan {
    position: absolute;
    right: 20px;
    top: 31px;
  }
  .mng-scan .btn-custom button {
    background-color: #ff4e00;
    border-radius: 50px;
    color: #fff;
    padding: 0px 0px;
    height: 30px;
    width: 100px !important;
  }
  .position_rel {
    position: relative;
  }