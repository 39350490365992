body {
  margin: 0;
  font-family: "Poppins", Arial, sans-serif !important;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf");
}
a {
  text-decoration: none !important;
}

ul {
  padding: 0px;
}
li {
  list-style-type: none;
}
input::-webkit-input-placeholder {
  color: #9ca3af !important;
}
input:-moz-placeholder {
  color: #9ca3af !important;
}
/*CSS Component*/
.primary-color {
  color: #2c97eb;
}
.secondry-color {
  color: #ff4e00;
}

.default-color {
  color: #374151;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
/*CSS Component*/
p {
  color: #535353;
  font-size: 16px;
  font-family: "Poppins", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.primary-btn {
  background-color: #275598;
  border: 1px solid #275598;
  padding: 12px 40px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 700;
  line-height: 20px;
  border-radius: 100px;
}
.primary-white-btn {
  background-color: #fff;
  border: 1px solid #275598;
  padding: 12px 40px;
  color: #275598;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 700;
  line-height: 20px;
  border-radius: 100px;
}

.secondry-btn {
  background-color: #ff4e00;
  border: 1px solid #ff4e00;
  padding: 12px 40px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 700;
  line-height: 20px;
  border-radius: 100px;
}
.secondry-white-btn {
  background-color: #fff;
  border: 1px solid #ff4e00;
  padding: 12px 40px;
  color: #ff4e00;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 700;
  line-height: 20px;
  border-radius: 100px;
}
h2.heading {
  color: #383838;
  font-size: 21px;
  font-family: "Poppins", Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
.b-radius-15 {
  border-radius: 15px;
}
.form-control-cus {
  border-radius: 10px;
  border: 1px solid #e5e7eb;
  height: 40px;
  color: #e5e7eb;
}
.input-label {
  color: #374151;
  font-size: 17px;
  line-height: 32px;
  line-height: 25px;
}
.login-logo {
  padding-bottom: 15px;
  max-width: 325px;
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 60px;
}
.nav-user {
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 32px;
  justify-content: space-between;
}
.user-welcome span {
  font-weight: 600;
  color: #ff4e00;
}
.truck-logout a {
  color: #275598;
  font-weight: 600;
  padding: 0 10px 0 0;
}
.login {
  background-color: #1656c0;
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.required {
  color: #f00;
  font-size: 15px;
}
.signin-btn {
  color: #fff;
  border-radius: 30px;
  padding: 10px 40px;
  border: 3px solid #fff;
  box-shadow: 0 8px 9px -4px rgba(0, 0, 0, 0.15),
    0 4px 18px 0 rgba(0, 0, 0, 0.05);
  background-position: center right 15px;
  font-size: 17px;
}
.signin-btn:hover {
  color: #fff;
}
.sideline {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ccc;
}
button {
  height: 50px;
}
.sideline:before,
.sideline:after {
  content: "";
  border-top: 1px solid #ebebeb;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}
.sideline:after {
  margin: 0 0 0 20px;
}
.rdt_TableHeadRow {
  background-color: transparent !important;
  border-bottom: 0 solid rgba(0, 0, 0, 0.12) !important;
}
.rdt_TableRow {
  margin: 5px 0 !important;
  border-radius: 5px;
  padding: 10px 0;
  font-size: 16px;
}
.rdt_TableCell {
  font-size: 16px;
}

.rdt_Table,
.rdt_Pagination {
  background-color: transparent !important;
  border-top: 0px solid rgba(0, 0, 0, 0.12) !important;
  font-family: "Poppins", Arial, sans-serif;
}
.inventory-count header {
  background-color: transparent !important;
  padding: 30px 0;
}

.rdt_TableCol {
  font-family: "Poppins", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #275598;
}
.top_head {
  color: #000;
  font-family: "Poppins", Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.btn-custom button {
  border: 0;
  background-color: #ff4e00;
  border-radius: 50px;
  color: #fff;
  padding: 5px 17px;
  width: 160px;
  height: 45px;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 700;
}
.btn-custom {
  float: right;
}
.delete_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit-btn button {
  padding: 5px 30px;
  height: 45px;
  border-radius: 50px;
  border: 1px solid #275598;
  background-color: #275598;
  color: #fff;

  float: right;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 700;
}
.cancel-btn button {
  padding: 5px 30px;
  height: 45px;
  border-radius: 50px;
  border: 1px solid #275598;
  background-color: transparent;
  color: #275598;

  font-family: "Poppins", Arial, sans-serif;
  font-weight: 700;
}
.page-heading h4 {
  font-weight: 600;
  color: #000000;
  font-size: 20px;
}
.page-heading img {
  margin: -7px 15px 0 0;
}

.search-icon {
  width: 24px;
  height: 24px;
  color: #ff4e00;
  position: absolute;
  z-index: 111;
  top: 12px;
  left: 13px;
}

.form-main {
  border-radius: 4px;
  background-color: transparent !important;
}
.bg-white {
  --bs-bg-opacity: 0 !important;
}
.form-content {
  border-radius: 4px;
  background-color: #fff !important;
  padding: 20px;
}
.white-box-sec {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 #d6d6d6;
}
.form-content label,
.white-box-sec label {
  color: #000000;
  /* font-weight: 600; */
  font-weight: 400;
}

.mw-30per {
  max-width: 30% !important;
}
.selectpicker {
  appearance: auto !important;
}
.error {
  font-size: 12px;
  color: red;
}
.items-text {
  display: flex;
  align-items: center;
}
.items-text h6 {
  font-weight: 600;
}
.conform_btn {
  display: flex;
  width: 133px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  border-radius: 20px;
  background: #275598;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  border: none;
}
.cancel_btn {
  color: #ff4e00;
  text-align: center;
  font-family: "Poppins", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border: none;
  padding: 10px;
  background-color: transparent;
}
.modal-footer,
.modal-body {
  padding: 0 15px !important;
}
.modal-header {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.modal-footer {
  border-top: none !important;
  padding-bottom: 15px !important;
}
.icon_style {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  opacity: 0.30000001192092896;
  background: #ff4e00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table_icon {
  display: flex;
  align-items: center;
}
.table-inner-content td {
  padding: 1rem 1rem !important;
}
.table-inner-content {
  border-radius: 10px;
}
.warehouse-name {
  font-family: "Poppins", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: #275598;
}
.inventory .rdt_TableRow:hover,
.warehouse .rdt_TableRow:hover {
  cursor: pointer !important;
}
.scan-item {
  display: flex;
  justify-content: flex-end;
}
.viewport {
  height: 240px;
  display: flex;
  width: 100%;
}
.item-name {
  width: "30%";
}

.confirm-box {
  z-index: 1000;
  position: absolute;
  left: 0px !important;
  top: 45%;
  right: 0px !important;
  float: none;
  margin: 0 auto;
}
.modal-title {
  font-family: "Poppins", Arial, sans-serif;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 26px; /* 130% */
  letter-spacing: -0.32px;
}
.confirm-box__overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.scan_body {
  padding-left: 0 !important;
}
.conform_modal h3 {
  font-family: "Poppins", Arial, sans-serif;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 26px; /* 130% */
  letter-spacing: -0.32px;
}
.scannerBtn {
  position: absolute;
  right: 1vw;
  top: 22.5vh;
  margin-bottom: 20px;
}
.conform_modal {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  width: 500px;
}
.conform_box_btn {
  display: flex;
  justify-content: right;
}

video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

#interactive.viewport {
  width: 320px;
  height: 300px;
  overflow: hidden;
}

#interactive.viewport canvas,
video {
  float: left;
  width: 320px;
  height: 300px;
}
.custom-cell {
  width: 1100px;
}

.btn-search button {
  background-color: gray;
}
.search-items {
  justify-content: space-between;
  position: relative;
  margin-top: 5px;
  width: 100%;
}

.react-autosuggest__container {
  width: 100%;
}
.selectBtn button {
  border-left: 0px !important;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background: #fff;
  border-right: 1px solid #bbbbbb;
  border-top: 1px solid #bbbbbb;
  border-bottom: 1px solid #bbbbbb;
  color: #adb5bd;
}

.selectBtn button:hover {
  border-left: 0px !important;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  background: #fff;
  color: #adb5bd;
  border-right: 1px solid #bbbbbb;
  border-top: 1px solid #bbbbbb;
  border-bottom: 1px solid #bbbbbb;
  /* height: 44px; */
}
.selectBtn .btn-check:checked + .btn,
.selectBtn :not(.btn-check) + .btn:active,
.selectBtn .btn:first-child:active,
.selectBtn .btn.active,
.selectBtn .btn.show {
  color: #000;
  background-color: #fff !important;
  border: 1px solid #bbbbbb !important;
  /* height: 44px; */
}
.search-items input {
  border-radius: 24px 0px 0px 24px;
  border: 1px solid #bbbbbb;
  height: 50px;
  border-right: hidden;
  padding-left: 38px;
  width: 100% !important;
}
.search-items input:focus-visible {
  outline: 0;
}
.react-autosuggest__suggestions-list {
  padding-left: 0;
}
.react-autosuggest__suggestion {
  padding: 5px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #ff4e00;
  color: #fff;
  padding: 5px;
}
.react-autosuggest__suggestions-container--open {
  background: #fff;
  padding: 2px 10px;
  margin-top: 10px;
  box-shadow: 0 0 4px 0px #a8a8a8;
  position: absolute;
  width: 350px;
  z-index: 11;
  border-radius: 8px;
}
.nav-dropdown {
  border: 1px solid #ff4e00 !important;
}

button.btn.btn-secondary.dropdown-toggle.setting-icon {
  background-color: initial;
  border: none;
  -webkit-appearance: none;
  height: 30px;
  margin: -2px 0 0;
  padding: 0;
}
.btn:focus-visible {
  box-shadow: none !important;
  outline: none !important;
}
img:focus-visible {
  outline: -webkit-focus-ring-color none !important;
}

.setting-barcode.show {
  top: 12px !important;
  left: -78px !important;
}
button.import-it {
  background-color: initial;
  border: 2px solid #ff4e00;
  color: #ff4e00;
  font-weight: 600;
  width: 180px;
}
.btn-custom-copy button {
  border: 0;
  background-color: #ff4e00;
  border-radius: 50px;
  color: #fff;
  padding: 0px 17px;
}

.selectpicker {
  top: auto;
  bottom: 100%;
}
/*media query*/
@media screen and (max-device-width: 630px) {
  .scannerBtn {
    position: absolute;
    right: 1vw;
    top: 50.5vh;
  }
}
@media only screen and (max-device-width: 768px) {
  .mw-30per {
    max-width: none !important;
  }
  .delete_icon {
    justify-content: end;
    margin-top: 10px !important;
  }
  .scannerBtn {
    position: absolute;
    right: 1vw;
    top: 23.5vh;
  }
}

@media only screen and (min-device-width: 800px) and (max-device-width: 820px) {
  .item-name {
    width: "100%";
  }

  .scannerBtn {
    position: absolute;
    right: 1vw;
    top: 20.5vh;
  }
}

@media only screen and (orientation: portrait) {
  /* Add this CSS to your stylesheet */
  .viewport {
    position: relative;
    width: 100%;
    max-width: 400px;
  }

  .drawingBuffer {
    height: auto;
    display: block;
    object-fit: cover;
  }
}

@media (min-width: 1025px) {
  .hideForDesktop {
    display: none;
  }
}
@media (max-width: 1024px) {
  .hideForSmallDevice {
    display: none;
  }
  .search-items input {
    /* padding: 8.4px; */
    padding-left: 58px;
    justify-content: center;
    position: relative;
  }
  .search-items {
    justify-content: center;
    width: 100%;
  }
  .search-small-device {
    justify-content: center;
    left: 23px;
  }
  .search-dummy {
    position: relative;
  }
}

/* Media query for small screens (adjust the width as needed) */
/* @media screen and (min-width: 1200px) {
  .custom-cell {
    width: 850px;
  }
} */
