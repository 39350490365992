/* style */
.content-mid {
  width: 75%;
  display: table;
  margin: 0 auto;
  height: 100vh;
}
.mng-login-des {
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
.login-bg {
  background-color: #fff;
  padding: 45px 30px;
  border-radius: 15px;
}
input.form-control.form-control-cus.verify-no {
  width: 40px;
  margin-right: 10px;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}
.form-group.eye-icon.pb-1 svg {
  fill: #ddd;
  position: absolute;
  right: 17px;
  top: 48px;
}
.form-group.eye-icon.pb-1 {
  position: relative;
}
.login-bg.forgot-password {
  padding: 120px 29px;
}
.google-login-container {
  justify-content: center;
  display: flex;
  margin-top: 20px;
}
@media (max-width: 560px) {
  .content-mid {
    width: 100%;
  }
}
