.Inventory-Charges {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 #27559840;
  text-align: center;
  padding: 40px 0 0 0;
  height: 445px;
  position: relative;
}
.Inventory-Charges h3 {
  font-size: 45px;
  font-weight: lighter;
}
.Inventory-Charges p {
  font-size: 20px;
  text-transform: uppercase;
}
.Inventory-Charges img {
  margin-top: 20px;
}

.home_content.inner-home-content {
  position: relative;
}
.Inventory-count {
  background-color: #eff2f8;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 #27559840;
  text-align: center;
  padding: 40px 0 0 0;
  /* height: 300px; */
  margin-bottom: 40px;
}

.img-footer {
  bottom: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
}
.Inventory-count h3 {
  font-size: 45px;
  font-weight: lighter;
}

.Inventory-count p {
  font-size: 20px;
  text-transform: uppercase;
}
.img-footer2 {
  position: relative;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}
.field-class .Inventory-Charges h3 {
  font-size: 65px;
}

.field-class .Inventory-Charges {
  text-align: left;
  padding: 50px 30px 50px 30px;
  height: max-content;
}

.field-img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
}
@media only screen and (max-device-width: 1180px) {
  .Inventory-Charges {
    margin-top: 16px;
  }
}
